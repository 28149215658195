<template>
  <div class="page">
    <div class="header bg-white">
      <q-toolbar class="q-pa-none">
        <q-img src="src/assets/png/logo.png" class="logo" />
        <q-space />
        <q-btn
          stretch
          no-caps
          flat
          @click="scrollTo(null)"
          label="Dịch vụ"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="scrollTo('guide')"
          stretch
          no-caps
          flat
          label="Hướng dẫn"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="scrollTo('price')"
          stretch
          no-caps
          flat
          label="Bảng giá"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="termDialog = true"
          stretch
          no-caps
          flat
          label="Chính sách"
          class="text-weight-regular header-btn"
        />
        <q-btn
          @click="linkto('home')"
          stretch
          no-caps
          flat
          label="Công ty"
          class="text-weight-regular header-btn q-mr-md"
        />
        <q-btn
          color="primary"
          @click="clickDownload"
          no-caps
          label="Tải ứng dụng"
          class="header-btn"
        />
        <q-btn-dropdown
          class="display-mobile"
          color="primary"
          flat
          dropdown-icon="menu"
        >
          <div class="row no-wrap q-pa-md">
            <q-list>
              <q-item @click="scrollTo(null)" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Dịch vụ</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="scrollTo('guide')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Hướng dẫn</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="scrollTo('price')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Bảng giá</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="termDialog = true" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Chính sách</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="linkto('home')" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Công ty</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="clickDownload" clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Tải ứng dụng</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-btn-dropdown>
      </q-toolbar>
    </div>
    <div class="body flex justify-center row">
      <div>
        <div class="slogan-content flex flex-center justify-center">
          <div class="slogan-content-body q-mx-xl">
            <p class="slogan-content-text-title">Giữ liên lạc với bệnh nhân</p>
            <p class="slogan-content-text-body">
              Biến nhu cầu khám bệnh từ xa thành dịch vụ chuyên nghiệp nhằm mang
              tới <span class="text-bolder">sự tư vấn chất lượng</span> cho bệnh
              nhân cũng như
              <span class="text-bolder">nguồn thu nhập chính đáng</span> cho bác
              sĩ.
            </p>
            <div class="slogan-div-btn display-desktop">
              <q-btn
                color="primary"
                @click="clickDownload"
                no-caps
                rounded
                icon-right="arrow_right_alt"
                label="Tải ứng dụng để mở phòng mạch online"
                class="text-bolder q-px-lg q-py-md q-mt-md slogan-btn"
              />
            </div>
            <div class="slogan-div-btn display-mobile">
              <q-btn
                color="primary"
                @click="clickDownload"
                no-caps
                rounded
                icon-right="arrow_right_alt"
                label="Mở phòng mạch online"
                class="text-bolder q-px-lg q-py-md q-mt-md slogan-btn"
              />
            </div>
          </div>
        </div>
      </div>
      <q-img
        fit="contain"
        src="src/assets/png/image1.png"
        class="slogan-image q-ml-xl"
      />
    </div>
    <div class="flex flex-center">
      <q-card class="card-services">
        <q-card-section
          class="flex flex-center text-bolder card-title q-pb-none"
        >
          Tại sao kết nối với bệnh nhân thông qua App
        </q-card-section>
        <q-card-section
          class="flex flex-center q-px-xl q-py-none service-wrap-content"
        >
          <div class="row full-width service-content">
            <p class="col q-pr-xl service-wrap-image">
              <q-img
                fit="contain"
                src="src/assets/png/image2.png"
                class="service-image"
              />
            </p>
            <div class="col case-content-body self-center q-gutter-lg">
              <p class="row">
                <q-icon size="md" name="o_lock" class="dot" />
                <span class="case-body col"
                  >Không cần chia sẻ số điện thoại cho bệnh nhân, đảm bảo quyền
                  riêng tư.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="portrait" class="dot" />
                <span class="case-body col"
                  >Tất cả hội thoại với bệnh nhân chỉ tập trung vào các vấn đề y
                  khoa.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="connect_without_contact" class="dot" />
                <span class="case-body col"
                  >Chủ động thời gian và không gian thăm khám.</span
                >
              </p>
              <p class="row">
                <q-icon size="md" name="local_atm" class="dot" />
                <span class="case-body col"
                  >Kênh thu nhập chính đáng và hiệu quả sau giờ làm việc</span
                >
              </p>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div ref="guideRef" class="flex flex-center bg-white section-3">
      <div
        class="flex flex-center text-bolder card-title q-pb-md full-width step-title"
      >
        Các bước tiến hành thăm khám từ xa
      </div>
      <q-card class="card-section-3 row">
        <div
          class="col-6 flex flex-center justify-center step-section-image display-mobile"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step1.png"
            class="step-image"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">01</div>
          <div class="card-subtitle">
            Chia sẻ danh thiếp mời bệnh nhân kết nối
          </div>
          <span>
            Sau khi thông tin bác sĩ đã được xác nhận, chúng tôi sẽ gửi danh
            thiếp giấy cũng như danh thiếp điện tử cho bác sĩ. Bác sĩ sẽ chia sẻ
            danh thiếp này cho bệnh nhân để mời kết nối với mình.
          </span>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-desktop"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step1.png"
            class="step-image"
          />
        </div>
        <div class="col-6 flex flex-center justify-center step-section-image">
          <q-img
            fit="contain"
            src="src/assets/png/step2.png"
            class="step-image"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">02</div>
          <div class="card-subtitle">Xác nhận yêu cầu tư vấn</div>
          <span>
            Yêu cầu tư vấn sẽ gửi tới bác sĩ. Bác sĩ xác nhận tư vấn, gửi lịch
            làm việc, giá cả tư vấn, hoặc yêu cầu thêm thông tin y khoa từ bệnh
            nhân.
          </span>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-mobile"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step3.png"
            class="step-image"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">03</div>
          <div class="card-subtitle">Tiến hành tư vấn theo lịch hẹn</div>
          <span>
            Ứng dụng cung cấp những tính năng cần thiết để bác sĩ có thể tiến
            hành buổi tư vấn trực tuyến như:
          </span>
          <div class="row q-ml-md q-mt-xs">
            <q-img size="md" src="src/assets/svg/dot.svg" class="dot-img" />
            <span class="col">Quản lý lịch hẹn.</span>
          </div>
          <div class="row q-ml-md q-mt-xs">
            <q-img size="md" src="src/assets/svg/dot.svg" class="dot-img" />
            <span class="col">Quản lý thông tin y khoa của bệnh nhân.</span>
          </div>
          <div class="row q-ml-md q-mt-xs">
            <q-img size="md" src="src/assets/svg/dot.svg" class="dot-img" />
            <span class="col">Thanh toán trực tuyến.</span>
          </div>
          <div class="row q-ml-md q-mt-xs">
            <q-img size="md" src="src/assets/svg/dot.svg" class="dot-img" />
            <span class="col">Video call,...</span>
          </div>
        </div>
        <div
          class="col-6 flex flex-center justify-center step-section-image display-desktop"
        >
          <q-img
            fit="contain"
            src="src/assets/png/step3.png"
            class="step-image"
          />
        </div>
        <div class="col-6 flex flex-center justify-center step-section-image">
          <q-img
            fit="contain"
            src="src/assets/png/step4.png"
            class="step-image"
            style="position: relative; bottom: -20px"
          />
        </div>
        <div class="col-6 self-center step-section-text bg-white q-px-xl">
          <div class="card-title">04</div>
          <div class="card-subtitle">Thanh toán và rút tiền trực tuyến</div>
          <span>
            Tất cả khoản thanh toán từ bệnh nhân đều được thanh toán trực tuyến
            và tổng hợp lại vào số dư của bác sĩ vào cuối tháng. Bác sĩ có thể
            rút tiền vào tài khoản ngân hàng của mình vào bất cứ lúc nào.
          </span>
        </div>
      </q-card>
    </div>

    <div ref="pricingRef" class="flex flex-center section-3">
      <div class="flex flex-center text-bolder card-title q-pb-md full-width">
        Bảng giá dịch vụ
      </div>
      <q-card class="card-section-4">
        <div class="bg-white">
          <q-card-section>
            <p class="text-center q-mt-md">
              <span class="price-text text-bolder text-primary">16,000</span>
              <span class="price-unit text-weight-regular"> đ/MAU</span>
            </p>
            <p class="q-mx-lg">
              <span class="text-bold">MAU</span> - Monthly Active User: là bệnh
              nhân trong danh sách của bác sĩ có yêu cầu tư vấn và được bác sĩ
              chấp nhận trong tháng
            </p>
            <div class="text-right q-mr-xl">
              <span @click="clickExample" class="text-primary cursor-pointer">
                Xem ví dụ
              </span>
            </div>
            <q-expansion-item
              header-style="display: none"
              :model-value="expansionPart"
              hide-expand-icon
            >
              <q-card>
                <q-card-section class="padding-example">
                  Tổng số Bệnh Nhân kết nối với Bác Sĩ là 100. <br />
                  Trong tháng Bác Sĩ khám 30 ca cho 20 Bệnh nhân (có người khám
                  nhiều hơn 1 lần). <br />
                  Số tiền Bác Sĩ trả cho hệ thống là: <br />
                  20MAU x 16,000đ = 320,000đ.
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-card-section>
          <q-separator inset />
          <q-card-section
            class="q-px-xl q-pt-xl q-pb-md flex flex-center wrap-section-4"
          >
            <div>
              <p class="row">
                <q-icon size="1.5rem" name="group" class="dot" />
                <span class="case-body col">
                  Không giới hạn số bệnh nhân kết nối
                </span>
              </p>
              <p class="row">
                <q-icon size="1.5rem" name="storage" class="dot" />
                <span class="case-body col">
                  Không giới hạn dung lượng lưu trữ
                </span>
              </p>
              <p class="row">
                <q-icon size="1.5rem" name="card_membership" class="dot" />
                <span class="case-body col">
                  Tặng miễn phí 200 danh thiếp bác sĩ
                </span>
              </p>
              <p class="row">
                <q-icon size="1.5rem" name="redeem" class="dot" />
                <span class="case-body col">
                  Tặng 30 MAU miễn phí khi đăng ký mới
                </span>
              </p>
              <p class="row">
                <q-icon size="1.5rem" name="celebration" class="dot" />
                <span class="case-body col">
                  Tặng 100 MAU khi đăng ký trong năm 2022
                </span>
              </p>
            </div>
          </q-card-section>
          <div class="text-center q-mb-lg">
            <q-btn
              color="primary"
              @click="clickDownload"
              no-caps
              rounded
              icon-right="arrow_right_alt"
              label="Tải ứng dụng để đăng ký"
              class="text-bolder q-px-lg q-py-sm"
            />
          </div>
        </div>
      </q-card>
    </div>

    <q-card class="footer">
      <div class="flex justify-between">
        <q-img src="src/assets/png/logo-footer.png" class="footer-logo" />
        <div class="flex justify-between">
          <div class="q-px-md flex justify-between q-gutter-lg">
            <div
              @click="clickTerm"
              class="text-right text-bold text-primary cursor-pointer self-center mobile-term"
            >
              Điều khoản sử dụng dịch vụ
            </div>
            <div
              @click="clickPrivacy"
              class="text-right text-bold text-primary cursor-pointer self-center mobile-term"
            >
              Chính sách bảo mật
            </div>
          </div>
          <div class="flex justify-between">
            <q-img
              @click="linkto('google')"
              src="src/assets/png/google-play.png"
              class="footer-logo cursor-pointer"
            />
            <q-img
              @click="linkto('apple')"
              src="src/assets/png/app-store.png"
              class="footer-logo cursor-pointer"
            />
          </div>
        </div>
      </div>
      <q-separator />
      <div
        class="flex justify-between text-caption text-grey-8 footer-2 q-mt-md"
      >
        <div
          @click="linkto('home')"
          style="width: 45%"
          class="cursor-pointer wrap-copy-right"
        >
          © 2022 Spring Knowledge Global Joint Stock Company. All rights
          reserved
        </div>
        <div class="text-center wrap-email" style="width: 19%">
          <q-icon name="email" />
          <a
            style="text-decoration: none"
            class="text-grey-8"
            href="mailto:support@kitor.vn"
            >support@kitor.vn</a
          >
        </div>
        <div
          @click="linkto('maps')"
          class="text-right wrap-address cursor-pointer"
          style="width: 36%"
        >
          <q-icon name="pin_drop" />74/13/4 Trương Quốc Dung, P.10, Phú Nhuận,
          TpHCM
        </div>
      </div>
    </q-card>
  </div>

  <q-dialog v-model="termDialog">
    <q-card style="max-width: 70vw">
      <q-card-section class="q-pa-lg">
        <div style="position: absolute; right: 10px; top: 10px">
          <q-btn
            size="0.6rem"
            outline
            round
            @click="termDialog = false"
            icon="close"
          />
        </div>
        <div class="q-mb-md">
          <div v-html="renderTerm"></div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="privacyDialog">
    <q-card style="max-width: 70vw">
      <q-card-section class="q-pa-lg">
        <div style="position: absolute; right: 10px; top: 10px">
          <q-btn
            size="0.6rem"
            outline
            round
            @click="privacyDialog = false"
            icon="close"
          />
        </div>
        <div class="q-mb-md">
          <div v-html="renderPrivacy"></div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from "vue";
import { useQuasar } from "quasar";
import gsheet from "src/services/google-sheet";
import { useEnhancer } from "@/app/enhancer";
import { PRIVACY_POLICY_PATIENT_MD } from "@/constants/privacy_policy_patient";
import { TERMS_OF_SERVICE_PATIENT_MD } from "@/constants/terms_of_service_patient";
import MarkDownIt from "markdown-it";

export default defineComponent({
  name: "RootPage",
  setup() {
    const initialState = {
      termDialog: false,
      renderTerm: "",
      privacyDialog: false,
      renderPrivacy: "",
      downloadDialog: false,
      loading: false,
      name: "",
      company: "",
      email: "",
      text: "",
      services: []
    };
    const guideRef = ref(null);
    const pricingRef = ref(null);
    const expansionPart = ref(null);
    const state = reactive({ ...initialState });

    const { router } = useEnhancer();
    const $q = useQuasar();

    const linkto = (link) => {
      if (link === "home") window.open("https://sk-global.biz/");
      if (link === "maps") window.open("https://goo.gl/maps/WqufioBCB9eZbJNk7");
      if (link === "apple")
        window.open("https://apps.apple.com/vn/app/kitor-doctor/id6446671656");
      if (link === "google")
        window.open(
          "https://play.google.com/store/apps/details?id=skglobal.prehr.doctorMobile"
        );
    };
    const scrollTo = (refClicked) => {
      let top = 0;
      if (refClicked === "guide") top = guideRef.value.offsetTop;
      if (refClicked === "price") top = pricingRef.value.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    };

    const clickDownload = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.match(/(Mac|iPhone|iPod|iPad)/i)) {
        linkto("apple");
      } else {
        linkto("google");
      }
    };

    const clickExample = () => {
      expansionPart.value = !expansionPart.value;
    };

    const clickPrivacy = async () => {
      state.privacyDialog = true;
      const md = new MarkDownIt();
      state.renderPrivacy = await md.render(PRIVACY_POLICY_PATIENT_MD);
    };

    const clickTerm = async () => {
      state.termDialog = true;
      const md = new MarkDownIt();
      state.renderTerm = await md.render(TERMS_OF_SERVICE_PATIENT_MD);
    };

    return {
      guideRef,
      pricingRef,
      expansionPart,
      clickExample,
      clickDownload,
      ...toRefs(state),
      linkto,
      scrollTo,
      clickPrivacy,
      clickTerm
    };
  }
});
</script>

<style lang="scss">
@import "src/assets/app.scss";
</style>
